import { Link } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "../assets/css/homepage.scss";
import Clutch from "../components/clutch";
import Layout from "../components/layout";
import Typewriter from "typewriter-effect";
import ReactTypingEffect from "react-typing-effect";
// import Clutch from "../components/clutch";
import Loading from "../components/loading";
import Slider from "../components/slider";
import Background from "../images/background-rocket.svg";
import BookmarkIcon from "../images/bookmark-icon.svg";
import chevronDown from "../images/chevron-down.svg";
import EmailIcon from "../images/email-icon.svg";
import Rocket from "../images/rocket3.svg";
import ShowProvider from "../components/showProvider";
import ContactUs from "../components/contactUs";
import Head from "../components/head";
import { Helmet } from "react-helmet";

function IndexPage({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [buttons, setButtons] = useState({});
  const strapiHost = process.env.GATSBY_API_URL;
  const nextSectionRef = useRef();
  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          homepage(locale: "${pageContext.intl.language}") {
            pageTitle
            Title
            title2
            title3
            title5
            description_clutch
            description
            description2
            description3
            description4
            description5
            counters_title
            number1
            number2
            number3
            number4
            counter1_desc
            counter2_desc
            counter3_desc
            counter4_desc
            grywalization_title
            grywalization_desc
            grywalization_icon {
              url
            }
            logo {
              url
            }
            icon1 {
              url
            }
            icon2 {
              url
            }
            icon3 {
              url
            }
            icon4 {
              url
            }
            img {
              url
            }
            technologies_title
            technologies{
              technology_icon{
                url
              }
            }
          }
          button(locale: "${pageContext.intl.language}") {
            showoffer
            contactus
            fulloffer
          }
        } `
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.homepage);
      setButtons(items.button);

      setLoading(false);
    });

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <div className="home__section1">
            <Layout contactUs={false} initial={false} nav={false}>
              <div className="home__section1__wrapper">
                <div className="home__section1__left1">
                  <h1 className="home__section1__title1">{data.Title}</h1>
                  {/* <h2 className="home__title2">{data.title2}</h2> */}
                  <Typewriter
                    options={{
                      strings: [data.title2],
                      autoStart: true,
                      loop: true,
                      wrapperClassName: "home__section1__title2",
                      cursorClassName: "home__section1__title2-cursor",
                    }}
                  />
                  <p className="home__section1__description">
                    {data.description}
                  </p>
                  <div className="home__section1__buttons">
                    <Link to="/contact">
                      <button className="btn btn-primary">
                        <img src={EmailIcon} />
                        {buttons.contactus}
                      </button>
                    </Link>
                    <Link to="/offer">
                      <button className="btn btn-outline-primary">
                        <img src={BookmarkIcon} />
                        {buttons.showoffer}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="home__section1__right1">
                  <div className="home__section1__right1-wrapper">
                    <img className="rocket__svg" src={Rocket} />
                    <img className="background__svg" src={Background} />
                    {/* <img src="http://localhost:4000/uploads/we_love_pizza_2d1283bfa1.jpg"/> */}
                  </div>
                </div>
                <div className="home__section1__scroll-down">
                  <button
                    onClick={() => {
                      nextSectionRef.current.scrollIntoView();
                    }}
                  >
                    <img src={chevronDown} />
                  </button>
                </div>
              </div>
            </Layout>
          </div>
          <div ref={nextSectionRef}>
            <Slider />
          </div>
          <div className="home__section3">
            <Layout contactUs={false} initial={false} nav={false}>
              <h2 className="home__section3-title">{data.title3}</h2>
              <div className="home__section3-content">
                {[
                  {
                    img: data.icon2.url,
                    title: data.description3,

                    link: "/shops",
                  },
                  {
                    img: data.icon4.url,
                    title: data.description5,

                    link: "/soft",
                  },
                  {
                    img: data.icon3.url,
                    title: data.description4,
                    link: "/website",
                  },
                  {
                    img: data.icon1.url,
                    title: data.description2,
                    link: "/outsourcing",
                  },
                ].map(({ img, title, link }, key) => (
                  <Link
                    to={link}
                    className="home__section3-rectangle"
                    key={key}
                  >
                    <img src={`${strapiHost}${img}`} />
                    <h2 className="home__section3-desc">{title}</h2>
                  </Link>
                ))}
              </div>
              <div className="home__section3__button-container">
                <Link to="/offer">
                  <button
                    className="btn btn-lg btn-success home__section3__button"
                    type="button"
                  >
                    {buttons.fulloffer}
                  </button>
                </Link>
              </div>
            </Layout>
          </div>

          <div className="home__section4">
            <Layout contactUs={false} initial={false} nav={false}>
              <div className="home__section4-wrapper">
                <div className="home__section4-left">
                  <Clutch />
                </div>
                <div className="home__section4-right">
                  <h2 className="realizations__title2">{data.title5}</h2>
                  <p className="realizations__description3">
                    {data.description_clutch}
                  </p>
                </div>
              </div>
            </Layout>
          </div>

          <div className="home__section5-technologies" id="technologies">
            <Layout contactUs={false} initial={false} nav={false}>
              <h2 className="home__section5-title">
                {data.technologies_title}
              </h2>
              <div className="home__section5-content">
                {data.technologies.map(({ technology_icon }, key) => (
                  <div className="home__section5-rectangle" key={key}>
                    <img src={`${strapiHost}${technology_icon.url}`} />
                  </div>
                ))}
              </div>
            </Layout>
          </div>

          <div className="home__section6-wrapper">
            <Layout contactUs={false} initial={false} nav={false}>
              <h2 className="home__section6-title">{data.counters_title}</h2>

              <div className="home__section6-counters">
                {[
                  {
                    numbers: data.number1,
                    title: data.counter1_desc,
                  },
                  {
                    numbers: data.number2,
                    title: data.counter2_desc,
                  },
                  {
                    numbers: data.number3,
                    title: data.counter3_desc,
                  },
                  {
                    numbers: data.number4,
                    title: data.counter4_desc,
                  },
                ].map(({ numbers, title }, key) => (
                  <div className="home__section6-single" key={key}>
                    <div className="home__section6-circle">
                      <CountUp end={numbers} duration={2} suffix=" +">
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <p
                              className="home__section6-number"
                              ref={countUpRef}
                            />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </div>
                    <p className="home__section6-desc">{title}</p>
                  </div>
                ))}
              </div>
            </Layout>
          </div>
          <ContactUs />
        </ShowProvider>
      )}
    </>
  );
}

export default IndexPage;
